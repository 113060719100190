'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';
export function Toaster() {
  let _$_1 = $$_1(512, "34.35", null, 36, null, null, null, null, null);
  const {
    toasts
  } = $$_1(4, "34.35", useToast(), 37, null, null, 1, null, _$_1);
  return $$_1(16, "34.35", <ToastProvider>
      {toasts.map(({
      id,
      title,
      description,
      action,
      ...props
    }) => {
      return $$_1(16, "34.35", <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && $$_1(16, "34.35", <ToastTitle>{title}</ToastTitle>, 41, null, null, 4, null, _$_1)}
              {description && $$_1(16, "34.35", <ToastDescription>{description}</ToastDescription>, 42, null, null, 5, null, _$_1)}
            </div>
            {action}
            {$$_1(16, "34.35", <ToastClose />, 43, null, null, 6, 1, _$_1)}
          </Toast>, 39, null, null, 3, null, _$_1);
    })}
      {$$_1(16, "34.35", <ToastViewport />, 44, null, null, 7, 1, _$_1)}
    </ToastProvider>, 38, null, null, 2, null, _$_1);
}
registerMetadata_1("34.35", 8, Toaster);